
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .MainContainer {
        position: absolute;
        top: 65px;
        padding-top: 1%;
        width: 100%;
        height: calc(100% - (2% + 65px));
        min-height: 70%;
    }
}